import useSelectedClasses from "hooks/useSelectedClasses"
import { useEffect, useState } from "react"
import { FormattedAppointments, Class, TimeSlot } from "types/shopScheduleTypes"
import { reportToSentry } from "utils/reportToSentry"
import { fetchClassTimeslots } from "views/ScheduleIndividualClasses/utils/utils"
import useAuthStore from "stores/auth"

export const useFormattedAppointments = ( classInfo : Class, timezone: string | null, seriesReference: Map<number, Map<number, string>>, excludeDays: Set<string> ) : { formattedTimeSlots: FormattedAppointments, error: string, loading: boolean } => {
  const [ formattedTimeSlots, setFormattedTimeSlots ] = useState<FormattedAppointments>({})
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string>( `` )

  const { buildAuthorizer } = useAuthStore()
  const authorization = buildAuthorizer()

  const { selectedClasses, addTimeslots } = useSelectedClasses()

  const defaultErrorMessage = `Oops... We ran into an issue grabbing the available times for your class.`

  useEffect( () => {
    if ( timezone ) {
      if ( classInfo[`${timezone}_timeslots`]?.length ) {
        // format the appointments data since the date picker is used by multiple features
        setError( `` )
        const buildFormattedTimeSlots:FormattedAppointments = {}
        classInfo[`${timezone}_timeslots`].forEach( ( timeSlot: TimeSlot ) => {
          const dateKey = timeSlot.timeslot_exact_timestamp.substring( 0, 10 )
          // this logic is in place to check if the user has already selected a class with the same date
          if ( excludeDays.has( dateKey ) ) return
          // this logic is in place to not allow users to schedule series classes out of order
          const curSeriesRef = classInfo?.class_series_pk ? seriesReference.get( classInfo.class_series_pk ) : null
          if ( curSeriesRef && curSeriesRef?.has( classInfo.class_sequence - 1 ) && timeSlot.timeslot_exact_timestamp.localeCompare( curSeriesRef.get( classInfo.class_sequence - 1 ) ?? `` ) < 0 ) return
          if ( buildFormattedTimeSlots[dateKey] ) {
            const appendSlot = buildFormattedTimeSlots[dateKey]
            appendSlot.push({
              start_time: timeSlot.timeslot_exact_timestamp,
              duration_minutes: timeSlot.duration_minutes,
              begin_time_pretty: timeSlot.begin_time_pretty,
              event_instance_id: timeSlot.event_instance_id
            })
            buildFormattedTimeSlots[dateKey] = appendSlot
          } else buildFormattedTimeSlots[dateKey] = [{
            start_time: timeSlot.timeslot_exact_timestamp,
            duration_minutes: timeSlot.duration_minutes,
            begin_time_pretty: timeSlot.begin_time_pretty,
            event_instance_id: timeSlot.event_instance_id
          }]
        })

        setFormattedTimeSlots( Object.assign({}, buildFormattedTimeSlots ) )
      } else if ( selectedClasses?.length ) handleGetClassTimeslots( classInfo.class_id )
    }

  }, [ classInfo, timezone ] )

  const handleGetClassTimeslots = ( classId: string ) => {
    setLoading( true )
    fetchClassTimeslots( classId, timezone, authorization )
      .then( data => {
        setLoading( false )
        if ( data?.data && data?.meta?.status === `OK` ) {
          const status = addTimeslots( data.data, timezone ?? `US/Eastern` )
          if ( !status ) {
            reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots` ), {
              selectedClasses: JSON.stringify( selectedClasses )
            })

            return setError( defaultErrorMessage )
          }
        } else {
          reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots`, {
            cause: error
          }), {
            selectedClasses: JSON.stringify( selectedClasses )
          })

          return setError( defaultErrorMessage )
        }
      })
      .catch( ( error: Error ) => {
        setLoading( false )
        reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots`, {
          cause: error
        }), {
          selectedClasses: JSON.stringify( selectedClasses )
        })

        return setError( defaultErrorMessage )
      })
  }

  return {
    formattedTimeSlots,
    error,
    loading
  }
}