import useSelectedClasses from 'hooks/useSelectedClasses'
import useSelectedBundle from 'hooks/useSelectedBundle'
import { useHistory } from 'react-router-dom'
import BundleCard from 'components/BundleCard/BundleCard'
import { Bundle, Class } from 'types/shopScheduleTypes'
import ActionClassTab from "./ActionClassTab"
import ConfirmClassTab from "./ConfirmClassTab"
import { BUNDLE_SELECTION_BASE_PATH } from 'routes'

type SummarySectionProps = {
  bundleDetails: Bundle | undefined;
  removeTimes: ( _classes: Class[] ) => void;
  step: number;
  toggleSkipScheduling: ( _classInfo: Class ) => void;
}

function SummarySection({ bundleDetails, removeTimes, toggleSkipScheduling } : SummarySectionProps ) : JSX.Element | null {

  const { clearBundle } = useSelectedBundle()

  const { selectedClasses } = useSelectedClasses()

  const history = useHistory()

  const handleClearBundle = () => {
    clearBundle()
    history.push( BUNDLE_SELECTION_BASE_PATH.concat( window.location.search ) )
  }

  const classes = selectedClasses?.length ? selectedClasses : bundleDetails?.classes

  const timeIsSelected = classes?.find( ( classItem: Class ) => {
    return Boolean( classItem.selected_timeslot )
  })

  if ( !classes?.length ) return null

  // display classes that have been scheduled already
  if ( timeIsSelected ) return (
    <div className="my-10 mx-auto px-5">
      {
        classes.filter( ( classDetails: Class ) => { return classDetails.selected_timeslot }).map( ( classDetails: Class ) => {
          return (
            <ConfirmClassTab
              key={classDetails.class_id}
              classInfo={classDetails}
              removeTimes={removeTimes}
              toggleSkipScheduling={toggleSkipScheduling}
            />
          )
        })
      }
    </div>
  )

  // UI for when user has not scheduled a class yet

  if ( bundleDetails ) return (
    <div className="w-full flex flex-col items-center justify-center py-16 px-5">
      <BundleCard bundleDetails={bundleDetails} readOnly />
      <p className="md:text-xl font-light text-center">{`You've selected ${bundleDetails?.bundle_title}`}</p>
      <p
        className="a"
        onClick={handleClearBundle}
      >{`To select a different classes, Tap Here`}</p>
    </div>
  )

  return (
    <div className="flex flex-col justify-center items-center mb-32 max-w-3xl w-full px-5">
      <p className="text-xl md:text-2xl font-header mt-10 mb-5 text-center">{`Your Class Selection`}</p>
      <div className="flex flex-col gap-5 w-full max-w-lg">
        {
          selectedClasses.map( ( classDetails: Class ) => {
            return (
              <ActionClassTab
                key={classDetails.class_id}
                classInfo={classDetails}
              />
            )
          })
        }
      </div>
    </div>
  )

}

export default SummarySection