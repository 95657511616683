import { useHistory } from 'react-router-dom'
import 'styles/login.css'
import useAuthStore from 'stores/auth'
export default function NewCustomers() : JSX.Element {

  const { preAuthFrom } = useAuthStore()
  const history = useHistory()

  const redirectToIntakeForm = () => {
    history.push( `/intake/lookup${preAuthFrom}` )
  }

  return (
    <div className="mx-auto flex flex-col items-center gap-2">
      <h1 className="heading">{`New Customers`}</h1>
      <p className="max-w-sm text-center">{`First time logging in? Create an account by submitting your insurance eligibility.`}</p>
      <button
        className="secondary-button my-2"
        onClick={redirectToIntakeForm}
      >
        {`Check Your Eligibility`}
      </button>
    </div>
  )
}
