import create from 'zustand'

type SeriesReference = {
  seriesReference: Map<number, Map<number, string>>;
  setSeriesReference: ( _series_pk: number | null, _sequence_number: number, _time: string ) => void;
  resetSeriesReference: () => void;
}

const useSeriesReference = create<SeriesReference>( ( set, get ) => {
  return {
    seriesReference: new Map(),
    setSeriesReference: ( series_pk: number | null, sequence_number: number, time: string ) => {
      if ( series_pk === null ) return
      const updateMap = get().seriesReference
      if ( !updateMap.has( series_pk ) ) updateMap.set( series_pk, new Map() )
      const seriesMap = updateMap.get( series_pk )
      seriesMap?.set( sequence_number, time )
      if ( seriesMap ) updateMap.set( series_pk, seriesMap )
      set({
        seriesReference: updateMap
      })
    },
    resetSeriesReference: () => {
      set({
        seriesReference: new Map()
      })
    }
  }
})

export default useSeriesReference