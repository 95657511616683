import React from 'react'
import { IndividualClassSrc } from 'assets'
import { useHistory } from 'react-router-dom'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'

function IndividualSelectionCard(): JSX.Element {
  const history = useHistory()

  const navigateToIndividualClasses = () => {
    history.push(
      INDIVIDUAL_SELECTION_BASE_PATH.concat( window.location.search )
    )
  }

  return (
    <div className="w-full max-w-lg rounded-lg ring-1 ring-primary mb-16 pb-10 relative mx-auto">
      <img
        src={IndividualClassSrc}
        alt="individual selection image"
        className="w-full rounded-t-lg object-contain bg-gray-white"
      />
      <div className="px-5">
        <p className="text-xl mt-5 mb-2 font-semibold">{`Build Your Own Bundle`}</p>
        <p className="text-lg mb-5">{`Not seeing exactly what you want? Create your own bundle!`}</p>
      </div>
      <button
        className="standard-button absolute -bottom-5 right-0 left-0 mx-auto whitespace-nowrap w-fit"
        onClick={navigateToIndividualClasses}
      >
        {`SELECT CLASSES INDIVIDUALLY`}
      </button>
    </div>
  )
}

export default IndividualSelectionCard
