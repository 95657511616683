import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import { MAINTENANCE_PAGE_PATH } from 'routes'
import useMaintenanceNav from 'hooks/useMaintenanceNav'
import { useHistory } from 'react-router-dom'
import { getMaintenancePageStatus } from 'components/MaintenanceListener/utils'
import { reportToSentry } from 'utils/reportToSentry'

const useApolloClient = () => {

  const { setPreviousPath, setIsNetworkError } = useMaintenanceNav()
  const history = useHistory()

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/graphql`
  })
  const authLink = setContext( ( _, { headers }) => {
    const token = sessionStorage.getItem( `magentoToken` )

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ``
      }
    }
  })

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError( ({ graphQLErrors, networkError }) => {
    // magento is down send users to maintenance page
    if ( networkError && !graphQLErrors ) {
      reportToSentry( new Error( `Magento Network Error` ), {
        networkError
      })
      setIsNetworkError( true )
      if ( !getMaintenancePageStatus() ) setPreviousPath( window.location.pathname.concat( window.location.search ) )
      history.push( MAINTENANCE_PAGE_PATH )
    }
  })

  const apolloClient = new ApolloClient({
    link: from( [ errorLink, authLink, httpLink ] ),
    fetchOptions: {
      mode: `cors`
    },
    cache: new InMemoryCache()
  })

  return apolloClient
}

export default useApolloClient


