import React from 'react'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import { useHistory } from 'react-router-dom'

function IntegratedPatientHistoryThankYou() : JSX.Element {
  const history = useHistory()

  return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-2xl mx-auto space-y-3">
      <div className="p-5 flex flex-col items-center justify-center gap-3">
        <h1 className="hub-heading text-center text-3xl">{`Your Appointment!`}</h1>
        <p className="text-center text-lg">{`Thank you for taking the time to fill this out!  ~Aeroflow Breastpumps x Lactation Link`}</p>
        <p
          className="text-lg my-10 a"
          onClick={() => { history.push( HER_HUB_PATH.concat( window.location.search ) ) }}
        >
          {`Go back to my Account`}
        </p>
      </div>
    </div>
  )
}

export default IntegratedPatientHistoryThankYou