import Header from 'components/Header'
import PropTypes from 'prop-types'

const Layout = ({ children }) => {

  return (
    <div>
      <Header />
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
