import create from 'zustand'

type MaintenanceStateType = {
  maintenancePreviousURL: string | null;
  clearPreviousPath: () => void;
  setPreviousPath: ( _path: string ) => void;
  isNetworkError: boolean;
  setIsNetworkError: ( _isNetworkError: boolean ) => void;
}

const useMaintenanceNav = create<MaintenanceStateType>( ( set ) => {
  return {
    maintenancePreviousURL: null,
    isNetworkError: false,
    clearPreviousPath: () => {
      set({
        maintenancePreviousURL: null
      })
    },
    setPreviousPath: ( path: string ) => {
      set({
        maintenancePreviousURL: path
      })
    },
    setIsNetworkError: ( isNetworkError: boolean ) => {
      set({
        isNetworkError
      })
    }
  }
})

export default useMaintenanceNav