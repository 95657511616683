import { SCHEDULE_BUNDLE_BASE_PATH } from 'views/ScheduleBundle/utils/constants'
import { SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH } from 'views/ScheduleIndividualClasses/utils/constants'
import { NotFound, OneOnOne, BundleSelection, ScheduleBundle, ScheduleIndividualClasses, IndividualSelection, HerHub, Login, Logout, IntegratedHistoryForm } from 'views'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import { Maintenance } from 'views/Maintenance'
import RescheduleDisplay from 'views/Reschedule/RescheduleDisplay'
import { ForgotPasswordForm } from './views/ForgotPassword'
import { IntakeLookup } from 'components/IntakeLookup'
import IntakeForm from 'components/IntakeForm'
import { IntakeThankYou } from 'views/ThankYou'
import { IntegratedPatientHistoryThankYou } from 'components/IntegratedPatientHistoryThankYou'

export const LOGIN_PAGE_PATH = `/login`
export const FORGOT_PASSWORD_PATH = `/forgot-password`
export const MAINTENANCE_PAGE_PATH = `/maintenance`
export const BUNDLE_SELECTION_BASE_PATH = `/education-classes`
export const INDIVIDUAL_SELECTION_BASE_PATH = `/schedule-your-classes`
export const SCHEDULE_ONE_ON_ONE_APPOINTMENT = `/schedule-appointment`
export const RESCHEDULE_ONE_ON_ONE_APPOINTMENT = `/reschedule-appointment`
export const INTEGRATED_PATIENT_HISTORY_PATH = `/patient-history`
export const INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH = `/patient-history/thank-you`

export const INTEGRATED_PATIENT_HISTORY_FEATURE_FLAG = false

const routes = [
  {
    path: LOGIN_PAGE_PATH,
    component: Login
  },
  {
    path: MAINTENANCE_PAGE_PATH,
    component: Maintenance
  },
  {
    path: BUNDLE_SELECTION_BASE_PATH,
    component: BundleSelection,
    authRoute: true
  },
  {
    path: [ SCHEDULE_ONE_ON_ONE_APPOINTMENT, RESCHEDULE_ONE_ON_ONE_APPOINTMENT ],
    component: OneOnOne,
    authRoute: true
  },
  {
    path: `/intake/lookup/:from`,
    component: IntakeLookup,
    exact: true
  },
  {
    path: `/intake/qualify/:from`,
    component: IntakeForm
  },
  {
    path: BUNDLE_SELECTION_BASE_PATH,
    component: BundleSelection,
    exact: true
  },
  {
    path: SCHEDULE_BUNDLE_BASE_PATH,
    component: ScheduleBundle,
    authRoute: true
  },
  {
    path: INDIVIDUAL_SELECTION_BASE_PATH,
    component: IndividualSelection,
    exact: true,
    authRoute: true
  },
  {
    path: SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH,
    component: ScheduleIndividualClasses,
    authRoute: true
  },
  {
    path: HER_HUB_PATH,
    component: HerHub,
    authRoute: true
  },
  {
    path: INTEGRATED_PATIENT_HISTORY_PATH,
    exact: true,
    component: IntegratedHistoryForm,
    authRoute: true
  },
  {
    path: INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH,
    component: IntegratedPatientHistoryThankYou,
    authRoute: true
  },
  {
    path: `/reschedule/:classId`,
    component: RescheduleDisplay,
    authRoute: true
  },
  {
    path: `/intake/thank-you`,
    exact: true,
    component: IntakeThankYou
  },
  {
    path: `/logout`,
    exact: true,
    component: Logout
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: ForgotPasswordForm
  },
  {
    path: `*`,
    component: NotFound
  }
]

export default routes