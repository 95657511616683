import React, { useMemo, useState, useEffect } from 'react'
import { StyledDayPicker, AppointmentButton } from './styled'
import { DAYS, MONTHS, WEEKDAYS_SHORT } from 'utils/constants'
import { addZeroIfNeeded, getMDY } from 'utils'
import 'react-day-picker/lib/style.css'
import MonthSelector from './MonthSelector'
import useAppointmentHelpers from 'stores/appointmentHelpers'
import { ThemedTimezoneSelector } from 'components/ThemedTimezoneSelector'
import AnimatedHeightDiv from 'components/AnimatedHeightDiv/AnimatedHeightDiv'
import { FormattedAppointments } from 'types/shopScheduleTypes'
import useSelectedClasses from 'hooks/useSelectedClasses'

// appointments need to be formatted to have the date string as a key and all of the appointment slots under that key
// key example: 2022-11-21

type DatePickerProps = {
  timeZone: string | null;
  setTimeZone?: React.Dispatch<React.SetStateAction<string>>;
  appointments: FormattedAppointments;
  startTime: string | null | undefined;
  handleTimeClick: ( _time: { start_time: string }) => void;
  onMonthChange: ( _m: Date ) => void;
  error?: string;
  dynamicMonthBoundary?: boolean;
  customTimzoneSelector?: boolean;
}

type FormattedTimeSlot = {
  start_time: string;
  lc_name?: string;
  duration_minutes: number;
  begin_time_pretty: string;
  event_instance_id: string;
}

const DatePicker = ({
  timeZone,
  setTimeZone,
  appointments,
  startTime,
  handleTimeClick,
  onMonthChange,
  error,
  customTimzoneSelector,
  // the below prop will not allow the user to scroll before the earliest appointment date or after the last appoinment date
  // if this prop is not passed it will default to allow the user to navigate to 6 months out
  dynamicMonthBoundary = true
} : DatePickerProps ) : JSX.Element => {

  const {staffRequired, setConsultantName} = useAppointmentHelpers()
  const { setSavedTimeSelection, savedTimeSelection, clearSavedTimeSelection, dayFromCal, setDayFromCal, clearDayFromCal } = useSelectedClasses()

  const [ selectedMonth, setSelectedMonth ]= useState<Date>( new Date() )

  // this use effect handles saving the users selection if they change their timezone
  useEffect( () => {
    if ( savedTimeSelection ) {
      let newStartTime:FormattedTimeSlot | undefined = undefined
      Object.keys( appointments ).every( apptKey => {
        newStartTime = appointments[ apptKey ]?.find( appt => {
          return appt.event_instance_id === savedTimeSelection
        })
        if ( newStartTime ) return false

        return true
      })
      if ( newStartTime ) {
        const savedStartTimeDate = appointmentDates.find( apptDate => {
          if ( !newStartTime?.start_time ) return false
          const newStartDate = dateFromKey( newStartTime.start_time.slice( 0, 10 ) )

          return apptDate.getMonth() === newStartDate.getMonth() && apptDate.getDate() === newStartDate.getDate() && apptDate.getFullYear() === newStartDate.getFullYear()
        })

        if ( savedStartTimeDate ) {
          setSelectedMonth( savedStartTimeDate )
          setDayFromCal( savedStartTimeDate )
          handleTimeClick({
            start_time: ( newStartTime as FormattedTimeSlot )?.start_time
          })
        }
      }
    }
  }, [ timeZone, appointments ] )

  // this is a helper function that takes a string date key used in the appointments object and returns the date key as a Date type for comparison
  const dateFromKey = ( key: string ) => {
    try {
      return new Date( parseInt( key.substring( 0, 4 ) ), parseInt( key.substring( 5, 7 ) ) - 1, parseInt( key.substring( key.length - 2, key.length ) ) )
    } catch { return new Date() }
  }

  const getFirstApptDate = ( m: Date ) => {
    if ( appointments ) {
      const monthCompare = addZeroIfNeeded( m.getMonth() + 1 ).toString()

      const firstApptKey = sortedAppointmentKeys.find( apptKey => {
        return apptKey.substring( 5, 7 ) === monthCompare && appointments[apptKey]?.length
      })

      return firstApptKey ? new Date( appointments[firstApptKey][0].start_time.substring( 0, 16 ) ) : m
    }

    return m
  }

  const [ appointmentDates, sortedAppointmentKeys ] = useMemo( () => {

    const apptKeys = Object.keys( appointments )

    apptKeys.sort( ( a, b ) => {
      return a < b ? -1 : 1
    })

    return [ apptKeys.map( d => {

      // in this case there should only be one required LC
      if ( staffRequired === 1 && appointments[d][0]?.lc_name ) setConsultantName( appointments[d][0].lc_name )

      const currentDate = d.split( `-` )

      return new Date(
        parseInt( currentDate[0] ),
        parseInt( currentDate[1] ) - 1,
        parseInt( currentDate[2] )
      )
    }), apptKeys ]
  }, [ appointments ] )

  const [ selectedDayLabel, times ] = useMemo( () => {

    if ( !dayFromCal ) return [ null, [] ]
    const { year, month, date, weekday } = getMDY( dayFromCal )

    if ( typeof year !== `number` || typeof month !== `number` || typeof date !== `number` || typeof weekday !== `number` ) return [ null, [] ]

    const dateKey = `${year}-${addZeroIfNeeded(
      month + 1
    )}-${addZeroIfNeeded( date )}`

    const timesMap:{ [key: string]: FormattedTimeSlot} = {}
    const times = appointments[dateKey] || []
    times.forEach( item => {
      timesMap[item.start_time] = item
    })

    return [
      `${DAYS[weekday]}, ${MONTHS[month]} ${addZeroIfNeeded( date )}`,
      Object.values( timesMap ).sort(
        ( a: FormattedTimeSlot, b: FormattedTimeSlot ) => { return a.start_time.localeCompare( b.start_time ) }
      )
    ]
  }, [ dayFromCal, appointments ] )

  const [ fromMonth, toMonth ] = useMemo( () => {
    const firstApptDate = dateFromKey( sortedAppointmentKeys[0] )

    setDayFromCal( firstApptDate )
    setSelectedMonth( firstApptDate )

    if ( !dynamicMonthBoundary ) {
      const sixMonthsFromNow = new Date()
      sixMonthsFromNow.setMonth( sixMonthsFromNow.getMonth() + 5 )

      return [ new Date(), sixMonthsFromNow ]
    }
    if ( !appointments || !sortedAppointmentKeys?.length ) {
      const constantFromMonth = new Date()
      const constantToMonth = new Date()
      constantToMonth.setMonth( constantToMonth.getMonth() + 3 )

      return [
        constantFromMonth,
        constantToMonth
      ]
    }

    return [
      firstApptDate,
      dateFromKey( sortedAppointmentKeys[ sortedAppointmentKeys.length - 1 ] )
    ]
  }, [ appointments, sortedAppointmentKeys ] )

  const handleMonthChangeReset = ( m: Date ) => {
    clearDayFromCal()
    clearSavedTimeSelection()
    setSelectedMonth( m )

    return handleChangeMonth( m )
  }

  const handleMonthSelection = ( e: string ) => {
    const selectedMonthIndex = MONTHS.findIndex( month => { return month === e })
    // if the selected month index is less than the from month index need to go to next calendar year
    const goToDate = new Date( fromMonth.getMonth() > selectedMonthIndex ? toMonth.getFullYear() : fromMonth.getFullYear(), selectedMonthIndex )
    clearDayFromCal()
    setSelectedMonth( goToDate )
    handleChangeMonth( goToDate )
  }

  const handleChangeMonth = ( m : Date ) => {
    setDayFromCal( getFirstApptDate( m ) )
    setSelectedMonth( m )
    onMonthChange( m )
  }

  return (
    <div>
      <div className="flex justify-center min-h-400 relative">
        <MonthSelector
          fromMonth={fromMonth}
          toMonth={toMonth}
          selectedMonth={selectedMonth}
          handleMonthSelection={handleMonthSelection}
        />
        <StyledDayPicker
          onDayClick={( day : Date ) => { return setDayFromCal( day ) }}
          month={selectedMonth}
          selectedDays={appointmentDates}
          weekdaysShort={WEEKDAYS_SHORT}
          onMonthChange={handleMonthChangeReset}
          fromMonth={fromMonth}
          toMonth={toMonth}
          renderDay={( day : Date ) => {
            return (
              <div className="date-number-wrapper">
                <div className="date-number">{day.getDate()}</div>
              </div>
            )
          }}
          modifiers={{
            birthday: dayFromCal
          }}
        />
      </div>

      <AnimatedHeightDiv
        display
        heightDependencies={[ dayFromCal, times, error ]}
        padHeight={40}
      >
        <div>
          {
            dayFromCal && !customTimzoneSelector && setTimeZone &&
            <ThemedTimezoneSelector timezone={timeZone ?? `US/Eastern`} setTimezone={setTimeZone} />
          }

          {selectedDayLabel && times.length > 0 && (
            <div className="text-center text-2xl mt-10 mb-5">
              {selectedDayLabel}
            </div>
          )}

          <div className="w-full my-0 mx-auto px-2 grid grid-cols-3 gap-3 justify-center items-center mb-10 overflow-hidden">
            {times &&
              times.map( ( time, i ) => {
                return (
                  <AppointmentButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    active={time.start_time === startTime}
                    onClick={() => {
                      setSavedTimeSelection( time.event_instance_id )

                      return handleTimeClick( time )
                    }}
                  >
                    <span className={`font-semibold whitespace-nowrap text-sm md:text-base`}>
                      {time.begin_time_pretty}
                    </span>
                    <br />
                    <span className={`lowercase duration-text whitespace-nowrap`}>
                      {`${time.duration_minutes} min${time.duration_minutes > 1 ? `s` : ``}`}
                    </span>
                  </AppointmentButton>
                )
              })}
          </div>
          {error && (
            <div className="text-error text-center text-xs">{error}</div>
          )}
        </div>

      </AnimatedHeightDiv>
    </div>
  )
}

export default DatePicker
