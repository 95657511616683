import { useHistory } from "react-router-dom"

export const SchedulingErrorBlock = ({message = `Oops we encountered an exception on our side...`, backButton = false} : { message?: string, backButton?: boolean }) : JSX.Element => {
  const history = useHistory()

  return (
    <div className=" w-full max-w-2xl flex flex-col justify-center min-h-400 mx-auto rounded">
      <p className="mx-12 my-4 text-center text-error text-lg font-light">{message}</p>
      <p className="mx-12 my-4 text-center text-error font-light">
        <span>
          {`Please reload the page and try again or contact customer service at `}
        </span>
        <span>
          <a className="cursor-pointer underline hover:opacity-80" href="tel:+18887323979">{`888-732-3979 `}</a>
        </span>
        <span>
          {` if the issue persists.`}
        </span>
      </p>
      <div className="mx-12 my-4 text-center flex flex-col items-center justify-center gap-4">
        {
          backButton && (
            <button
              className="primary-button"
              onClick={() => {
                history.goBack()
              }}
            >
              {`Go Back`}
            </button>
          )
        }
        <button
          className="primary-button"
          onClick={() => {
            window.location.reload()
          }}
        >{`Refresh Page`}</button>
      </div>
    </div>
  )
}

export default SchedulingErrorBlock