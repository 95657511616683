import create from 'zustand'

interface AuthState {
  evaluateIsLoggedIn: () => boolean;
  getCurrentToken: () => string | null;
  setCurrentToken: ( _magentoToken : string ) => void;
  removeCurrentToken: () => void;
  getCurrentHash: () => string;
  buildAuthorizer: () => string; // 'Bearer <token>' | 'PatientHash <hash>' | ''
  preAuthFrom: string;
  setPreAuthFrom: ( _from: string ) => void;
}



/*
 * This site will have two forms of Auth
 * 1. Hash-based auth under the passing of a sgh or txt token through the search params
 * 2. Magento token based
 */
const useAuthStore = create<AuthState>()( ( set, get ) => ({
  getCurrentToken: () => {
    const searchParams = new URLSearchParams( window.location.search )
    const mct = searchParams.get( `mct` )

    return sessionStorage.getItem( `magentoToken` ) ?? mct
  },
  setCurrentToken: ( magentoToken: string ) => {
    return sessionStorage.setItem( `magentoToken`, magentoToken )
  },
  removeCurrentToken: () => sessionStorage.removeItem( `magentoToken` ),
  getCurrentHash: () => {
    const searchParams = new URLSearchParams( window.location.search )
    const hash = searchParams.get( `sgh` ) ?? searchParams.get( `txt` ) ?? ``

    return hash
  },
  evaluateIsLoggedIn: () => {
    return Boolean( get().getCurrentHash()?.length ) || Boolean( get().getCurrentToken()?.length ) || Boolean( new URLSearchParams( window.location.search ).get( `mct` ) )
  },
  buildAuthorizer: () => {
    const isHash = Boolean( get().getCurrentHash().length )
    if ( isHash ) return `PatientHash ${get().getCurrentHash()}`
    else if ( get().getCurrentToken() ) return `Bearer ${get().getCurrentToken()}`

    return ``
  },
  preAuthFrom: `/my-account`,
  setPreAuthFrom: ( _from ) => set({
    preAuthFrom: _from
  })
}) )

export default useAuthStore