import { AWSResponse } from "types/aws"
import { PatientHistoryForm } from "types/integratedPatientHistory"

export const submitPatientHistoryForm = async ( auth: string, values: PatientHistoryForm ) : Promise<AWSResponse<undefined>> => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/patient-history`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'Authorization': auth
    },
    body: JSON.stringify( values )
  })

  return await response.json()
}