import { getSource } from "utils/getSource"

export const fetchClassTimeslots = async ( classId, timezone, authorization ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/classes/${classId}?tz=${timezone}&source=${getSource()}`, {
    headers: {
      "Authorization": authorization
    }
  })

  return await response.json()
}