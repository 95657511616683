import { Paper } from '@mui/material'
import RadioButtonOptions from 'components/Forms/components/RadioButtonOptions'
import React from 'react'
import { PatientHistoryForm } from 'types/integratedPatientHistory'

function PageTwoQuestions({ values, page, onChange } : {
  page: number,
  values: PatientHistoryForm,
  onChange: ( _name: string, _value: any ) => void,
}) : JSX.Element | null {

  if ( page !== 2 ) return null

  return (
    <div className="w-full space-y-10">
      <Paper className="p-10 border-3 border-solid border-primary space-y-3">
        <h2 className="text-xl font-semibold text-center">{`Postpartum Screening`}</h2>
        <p className="text-center">{`OPTIONAL - Please complete this quick survey even if you have done it elsewhere at another time. If you would rather skip it, scroll to the bottom and click Submit.`}</p>
        <p className="text-center">{`We would like to know how you are feeling. Please check the answer that comes closest to how you have felt IN THE PAST 7 DAYS, not just how you feel today. IN THE PAST 7 DAYS:`}</p>
      </Paper>
      <RadioButtonOptions
        id="been_able_to_laugh"
        name="been_able_to_laugh"
        value={values.been_able_to_laugh}
        submitType="multiple"
        label="I have been able to laugh and see the funny side of things."
        options={[
          {
            name: `As much as I always could`,
            value: 3
          },
          {
            name: `Not quite so much now`,
            value: 2
          },
          {
            name: `Definitely not so much now`,
            value: 1
          },
          {
            name: `Not at all`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="looked_forward_to_things"
        name="looked_forward_to_things"
        value={values.looked_forward_to_things}
        submitType="multiple"
        label="I have looked forward with enjoyment to things."
        options={[
          {
            name: `As much as I ever did`,
            value: 3
          },
          {
            name: `Rather less than I used to`,
            value: 2
          },
          {
            name: `Definitely less than I used to`,
            value: 1
          },
          {
            name: `Hardly at all`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="blamed_myself"
        name="blamed_myself"
        value={values.blamed_myself}
        submitType="multiple"
        label="I have blamed myself unnecessarily when things went wrong."
        options={[
          {
            name: `Yes, most of the time`,
            value: 3
          },
          {
            name: `Yes, some of the time`,
            value: 2
          },
          {
            name: `Not very often`,
            value: 1
          },
          {
            name: `No, never`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="anxious_or_worried"
        name="anxious_or_worried"
        value={values.anxious_or_worried}
        submitType="multiple"
        label="I have been anxious or worried for no good reason."
        options={[
          {
            name: `No, not at all`,
            value: 0
          },
          {
            name: `Hardly ever`,
            value: 1
          },
          {
            name: `Yes, sometimes`,
            value: 2
          },
          {
            name: `Yes, very often`,
            value: 3
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="scared_or_panicky"
        name="scared_or_panicky"
        value={values.scared_or_panicky}
        submitType="multiple"
        label="I have felt scared or panicky for no very good reason."
        options={[
          {
            name: `Yes, quite a lot`,
            value: 3
          },
          {
            name: `Yes, sometimes`,
            value: 2
          },
          {
            name: `No, not much`,
            value: 1
          },
          {
            name: `No, not at all`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="feeling_overwhelmed"
        name="feeling_overwhelmed"
        value={values.feeling_overwhelmed}
        submitType="multiple"
        label="Things have been getting on top of me"
        options={[
          {
            name: `Yes, most of the time I haven't been able to cope at all`,
            value: 3
          },
          {
            name: `Yes, sometimes I haven't been coping as well as usual`,
            value: 2
          },
          {
            name: `No, most of the time I have coped quite well`,
            value: 1
          },
          {
            name: `No, I have been coping as well as ever`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="unhappy_and_difficulty_sleeping"
        name="unhappy_and_difficulty_sleeping"
        value={values.unhappy_and_difficulty_sleeping}
        submitType="multiple"
        label="I have been so unhappy that I have had difficulty sleeping."
        options={[
          {
            name: `Yes, most of the time`,
            value: 3
          },
          {
            name: `Yes, sometimes`,
            value: 2
          },
          {
            name: `Not very often`,
            value: 1
          },
          {
            name: `No, not at all`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="sad_or_miserable"
        name="sad_or_miserable"
        value={values.sad_or_miserable}
        submitType="multiple"
        label="I have felt sad or miserable."
        options={[
          {
            name: `Yes, most of the time`,
            value: 3
          },
          {
            name: `Yes, quite often`,
            value: 2
          },
          {
            name: `Not very often`,
            value: 1
          },
          {
            name: `No, not at all`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="unhappy_have_been_crying"
        name="unhappy_have_been_crying"
        value={values.unhappy_have_been_crying}
        submitType="multiple"
        label="I have been so unhappy that I have been crying."
        options={[
          {
            name: `Yes, most of the time`,
            value: 3
          },
          {
            name: `Yes, quite often`,
            value: 2
          },
          {
            name: `Only occasionally`,
            value: 1
          },
          {
            name: `No, never`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="thoughts_of_harming_myself"
        name="thoughts_of_harming_myself"
        value={values.thoughts_of_harming_myself}
        submitType="multiple"
        label="The thought of harming myself has occurred to me."
        options={[
          {
            name: `Yes, quite often`,
            value: 3
          },
          {
            name: `Sometimes`,
            value: 2
          },
          {
            name: `Hardly ever`,
            value: 1
          },
          {
            name: `Never`,
            value: 0
          }
        ]}
        onChange={onChange}
      />
    </div>
  )
}

export default PageTwoQuestions