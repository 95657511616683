import { Bundle } from 'types/shopScheduleTypes'
import create from 'zustand'

type SelectedBundleState = {
  selectedBundle: Bundle | null;
  clearBundle: () => void;
  setSelectedBundle: ( _bundle: Bundle ) => void;
}

const useSelectedBundle = create<SelectedBundleState>( ( set ) => {
  return {
    selectedBundle: null,
    clearBundle: () => {
      set({
        selectedBundle: null
      })
    },
    setSelectedBundle: ( bundle: Bundle ) => {
      set({
        selectedBundle: bundle
      })
    }
  }
})

export default useSelectedBundle