import React, { useState, useEffect, useRef, ChangeEvent } from "react"
import { TextInput } from "../Forms/components"
import DOBInput from "../TextField/DOBInput"
import { DOBCheckerSchema } from 'utils/validationSchema'
import ButtonLoader from "components/ButtonLoader"
import { useHistory } from "react-router-dom"
import usePatientDetails from "hooks/usePatientDetails"
import { ShopSchedulePatient } from "types/shopScheduleTypes"
import useAuthStore from "stores/auth"
import useIntakeStore from "stores/intake"

export default function IntakeLookupForm() : JSX.Element {

  const [ email, setEmail ] = useIntakeStore( state => [ state.email, state.setEmail ] )
  const [ dob, setDob ] = useIntakeStore( state => [ state.dob, state.setDob ] )
  const [ errors, setErrors ] = useState({
    dob: ``,
    email: ``
  })
  const [ isLoading, setIsLoading ] = useState( false )

  const history = useHistory()
  const { fetchPatientDetails } = usePatientDetails()
  const { preAuthFrom } = useAuthStore()

  const emailRef = useRef<HTMLInputElement>( null )

  useEffect( () => {
    // autofocus on mount
    emailRef?.current?.focus()
  }, [] )

  function handleFormSubmit( e : React.FormEvent ) {
    e.preventDefault()
    setErrors({
      dob: ``,
      email: ``
    })

    const normDataValues = {
      email,
      dob
    }
    DOBCheckerSchema.validate( normDataValues, {
      abortEarly: false
    })
      .then( () => {
        setIsLoading( true )
        fetchPatientDetails({
          dob,
          email
        }).then( ( patientDetails ) => {
          setIsLoading( false )
          const fromUrl = window.location.pathname.split( `/` )[3]
          const from = ( fromUrl?.length ) ? `/${fromUrl}` : preAuthFrom
          if ( !patientDetails ) return history.push( `/intake/qualify${from}` )
          else history.push( `${from}?sgh=${( patientDetails as ShopSchedulePatient ).unique_hash}` )
        })
          .catch( ( e ) => {
            console.error( `Error fetching patient details`, e )
            setIsLoading( false )
          })
      })
      .catch( err => {
        const errors = err.inner.reduce(
          ( acc : any, error : any ) => {
            return {
              ...acc,
              [error.path]: error.message
            }
          },
          {}
        )
        setErrors( errors )
      })
  }


  return (
    <form onSubmit={handleFormSubmit} className="max-w-lg flex flex-col gap-8 sm:mx-4 md:mx-auto">
      <h1 className="text-lg text-center">{`Check your eligibility`}</h1>
      <TextInput
        className="input w-full"
        value={email}
        onChange={( e: ChangeEvent<HTMLInputElement> ) => setEmail( e.target.value )}
        reference={emailRef}
        type="text"
        name={`email`} label={`Email`} formatter={undefined}
        validator={undefined}
        errorMessage={errors.email}
      />

      <>
        <p className="text-sm font-semibold -mb-7 mt-4">{`Date of Birth`}</p>
        <DOBInput
          errorClassName="text-error text-xs pl-3.5 pt-2 -mt-8"
          error={errors.dob}
          onChange={( dob ) => setDob( dob )}
        />
      </>

      <button type="submit" className="primary-button primary-button w-40 mx-auto" disabled={isLoading}>
        {isLoading ? (
          <span className="flex items-center justify-center">
            <ButtonLoader />
          </span> ) : ( `Next` )
        }
      </button>
    </form>
  )
}