import create from 'zustand'

type IntakeStore = {
  email: string;
  setEmail: ( _email: string ) => void;
  dob: string;
  setDob: ( _dob: string ) => void;
}

const useIntakeStore = create<IntakeStore>( ( set ) => {
  return {
    email: ``,
    setEmail: ( _email ) => {
      set({
        email: _email
      })
    },
    dob: ``,
    setDob: ( _dob ) => {
      set({
        dob: _dob
      })
    }
  }
})

export default useIntakeStore