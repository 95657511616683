import routes from 'routes'
import { Switch, Route } from 'react-router-dom'
import { AuthRoute } from '.'

function RouteMapping() {

  return (
    <Switch>
      {
        routes.map( ( route ) => {
          const { authRoute, ...routeProps} = route

          if ( authRoute ) { // routeProps.path may be an array
            return <AuthRoute key={`${routeProps.path}`} {...routeProps} />
          }

          return <Route key={`${routeProps.path}`} {...routeProps} />
        })
      }
    </Switch>
  )
}

export default RouteMapping