
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

type Option = {
  id?: string
  name: string,
  value?: string | number
}

function RadioButtonOptions({
  id,
  name,
  label,
  onChange,
  labelClassName = `text-base font-semibold mb-1.5`,
  options,
  value,
  submitType = `single`,
  required = false
}: {
  id: string
  name: string
  label: string
  options: Option[]
  submitType?: string
  value?: string | number | Option
  onChange: ( _name: string, _value: any ) => void
  labelClassName?: string
  required?: boolean
}) : JSX.Element {
  return (
    <div className="space-y-3 text-sm">
      <label htmlFor={id} className={labelClassName}>
        {label}{required && <span className="text-error font-normal">{`*`}</span>}
      </label>
      <RadioGroup id={id}>
        {
          options?.map( ( option ) => {
            const isSelected = submitType === `single` ? ( option.value ?? option.name ) === value : ( option?.value ?? option?.name ) === ( ( value as Option )?.value ?? ( value as Option )?.name )

            return <FormControlLabel
              key={option.id} id={option.id} value={option.value ?? option?.name}
              control={<Radio className="text-xs" checked={isSelected} color="primary" />}
              label={option.name} componentsProps={{
                typography: {
                  sx: {
                    fontSize: `14px`,
                    fontWeight: `300`
                  }
                }
              }} onChange={() => { onChange( name, submitType === `single` ? option.name : option ) }}
            />
          })
        }
      </RadioGroup>
    </div>
  )
}

export default RadioButtonOptions