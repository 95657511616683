import { online_classes, plus_ico } from "assets/herHub"
import { useState } from "react"
import Tile from "../Tile"
import Expanded from "./Expanded"
import useClassesWithDescription from "hooks/useScheduledClasses"
import YourNextClassTile from "../YourNextClass/YourNextClassTile"
import RingLoader from "components/RingLoader"
import { SchedulingErrorBlock } from "components/SchedulingErrorBlock"

export default function ClassManagement() : JSX.Element | null {

  const { scheduledClasses, completedClasses, classProducts, error, noClasses, loading, nextClass } = useClassesWithDescription()
  const [ isExpanded, setIsExpanded ] = useState( true )
  const [ hideActions, setHideActions ] = useState<boolean>( false )

  if ( error ) return <SchedulingErrorBlock message="Oops... We encountered an error attempting to grab your upcoming classes. Please reload the page and try again." />

  // Hide the tile if there are no classes
  if ( noClasses ) return null
  if ( loading && isExpanded ) return <div className="my-4"><RingLoader /></div>

  if ( ( scheduledClasses?.length || completedClasses?.length ) && isExpanded ) {
    return (
      <>
        <YourNextClassTile
          class={nextClass}
          classDetails={classProducts}
          scheduledClasses={scheduledClasses}
          setHideActions={setHideActions}
          hideActions={hideActions}
        />
        <Expanded
          scheduledClasses={scheduledClasses}
          completedClasses={completedClasses}
          classDetails={classProducts}
          error={error}
          hideActions={hideActions}
          onLess={() => { setIsExpanded( false ) }}
        />
      </> )
  }

  return (
    <>
      <YourNextClassTile
        class={nextClass}
        classDetails={classProducts}
        scheduledClasses={scheduledClasses}
        setHideActions={setHideActions}
        hideActions={hideActions}
      />
      <Tile
        className="flex gap-6"
      >
        <img
          src={online_classes} className="self-center mb-10" width={90}
          height={70}
        />
        <div>
          <h1 className="hub-heading text-gray-dark">{`Class Management`}</h1>
          <ul className="list-disc ml-4">
            <li>{`View upcoming classes`}</li>
          </ul>
          <div
            className="flex gap-2 a ml-1 mt-1"
            onClick={() => { return setIsExpanded( true ) }}
          >
            <img src={plus_ico} width={17.5} height={17.5} />
            <p className="self-center">{`Tap to expand`}</p>
          </div>
        </div>
      </Tile>
    </>
  )
}