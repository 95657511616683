import { useEffect } from 'react'
import { checkMaintenanceFlag, getMaintenancePageStatus } from './utils'
import { useHistory } from 'react-router-dom'
import { MAINTENANCE_PAGE_PATH } from 'routes'
import useMaintenanceNav from 'hooks/useMaintenanceNav'
import { reportToSentry } from 'utils/reportToSentry'

function MaintenanceListener() : null {

  const history = useHistory()
  const isMaintenancePage = getMaintenancePageStatus()

  const { setPreviousPath } = useMaintenanceNav()

  useEffect( () => {
    checkMaintenanceFlag()
      .then( data => {
        if ( data?.meta?.status === `OK` && data?.data?.status ) {
          // save where the user was in case they keep their screen up and site is taken out of maintenance mode
          if ( !isMaintenancePage ) setPreviousPath( location.pathname.concat( location.search ) )

          history.push( MAINTENANCE_PAGE_PATH )
        }
      })
      .catch( () => {
        reportToSentry( new Error( `Lactation Link: Error getting maintenance status from the listener` ) )
      })
  }, [] )

  return null

}

export default MaintenanceListener