import React, { useEffect } from 'react'
import useAppointmentHelpers from 'stores/appointmentHelpers'
import { useHistory } from 'react-router-dom'

function OneOnOneThankYou() : JSX.Element {

  const { selectedAppointment } = useAppointmentHelpers()
  const history = useHistory()

  useEffect( () => {
    if ( !selectedAppointment ) return history.push( `/schedule-appointment`.concat( window.location.search ) )
  }, [] )

  return (
    <div className="max-w-lg px-10 flex flex-col mx-auto justify-center items-center mt-5">
      <p className="text-4xl md:text-5xl font-header mt-5 mb-3 text-center">{`You're All Set!`}</p>
      <p className="text-lg md:text-xl mb-10 font-light">{`A confirmation has been sent to your email.`}</p>
      <p className="text-2xl md:text-3xl mb-10">{`${selectedAppointment?.scheduled_date_pretty}, ${selectedAppointment?.begin_time_pretty}`}</p>
      <p className="text-xl md:text-2xl mb-10">{selectedAppointment?.lc_name}</p>
      <p
        className="a text-lg"
        onClick={() => {
          history.push( `/my-account`.concat( window.location.search ) )
        }}
      >
        {`Go to My Scheduling Dashboard`}
      </p>
    </div>
  )
}

export default OneOnOneThankYou