import { ClassProduct, ScheduledClass } from "types/shopScheduleTypes"
import { useState } from "react"
import ClassDetailsModal from "./ClassDetailsModal"
import { checkmark } from "assets/herHub"
import postCancelClass from 'apis/postCancelClass'
import useHerHubStore from "stores/herHub"
import { reportToSentry } from "utils/reportToSentry"
import CancelModal from "./CancelModal"
import useSeriesClasses from "../hooks/useSeriesClasses"
import { Link, useHistory } from "react-router-dom"
import fetchZoomLinkTracking from "apis/postZoomLinkTracking"
import useAuthStore from "stores/auth"
import { INDIVIDUAL_SELECTION_BASE_PATH } from "routes"
import tailwindColors from "config/tailwindColors"
import { AddToCalendarButton } from "add-to-calendar-button-react"
import { getCalendarEventDescription } from "utils"
import { getTimeString } from 'utils/time'

type ClassProps = {
  id?: string;
  completed?: boolean;
  classDetails?: ClassProduct;
  scheduledClasses: ScheduledClass[];
  hideActions?: boolean;
} & ScheduledClass

export default function Class({id, completed = false, hideActions = false, classDetails, scheduledClasses, ...scheduledClass} : ClassProps ) : JSX.Element {

  const [ showDetailsModal, setShowDetailsModal ] = useState( false )
  const [ showCancelModal, setShowCancelModal ] = useState( false )

  const { addRecentCancel } = useHerHubStore()
  const { buildAuthorizer } = useAuthStore()
  const history = useHistory()

  const seriesClasses = useSeriesClasses( scheduledClass, scheduledClasses )

  const handleCancelClass = ( seriesClasses?: ScheduledClass[] ) => {
    // if class is a series class cancel other classes in the series
    if ( seriesClasses?.length ) {
      seriesClasses.forEach( ( c: ScheduledClass ) => {
        addRecentCancel({
          ...c
        })
      })
      // fire off cancel classes in parallel
      Promise.all<Promise<Response> | void>(
        seriesClasses.map( async ( c: ScheduledClass ) => {
          return postCancelClass( c.event_instance_id, c.event_attendance_id )
        }) )
        .catch( ( err: Error ) => {
          reportToSentry( new Error( `Hersource Hub: Error canceling series classes`, {
            cause: err
          }), {
            seriesClasses: JSON.stringify( seriesClasses )
          })
          console.error( err )
        })
    } else {
      addRecentCancel({
        ...scheduledClass
      })
      postCancelClass( scheduledClass.event_instance_id, scheduledClass.event_attendance_id )
        .catch( ( err ) => {
          reportToSentry( new Error( `Hersource Hub: Error canceling class`, {
            cause: err
          }), {
            scheduledClass: JSON.stringify( scheduledClass )
          })
          console.error( err )
        })
    }

  }
  const handleLaunchZoom = () => {
    fetchZoomLinkTracking( buildAuthorizer(), scheduledClass.zoom_link, scheduledClass.event_instance_id ).catch( ( e ) => {
      reportToSentry( e, {
        scheduledClass: JSON.stringify( scheduledClass )
      })
    })

    window.open( scheduledClass.zoom_link, `_blank`, `noreferrer` )
  }

  const handleRetakeClass = () => {
    const updatedURLSearchParams = new URLSearchParams( window.location.search )
    updatedURLSearchParams.set( `class_id`, scheduledClass.class_id )
    history.push( `${INDIVIDUAL_SELECTION_BASE_PATH}?${updatedURLSearchParams.toString()}` )
  }

  const hideAddToCalendar = ( id: string ) => {
    const addToCalendarText = document.getElementById( `add-to-calendar-text-${id}` )
    addToCalendarText?.classList.add( `hidden` )
  }

  const displayAddToCalendar = ( id: string ) => {
    const addToCalendarText = document.getElementById( `add-to-calendar-text-${id}` )
    addToCalendarText?.classList.remove( `hidden` )
  }

  const startTime = ( new Date( scheduledClass.start_time_utc ?? scheduledClass.timeslot_exact_timestamp ) ).toTimeString()
    .split( ` ` )[0].substring( 0, 5 )
  const endTime = scheduledClass.end_time_utc ? ( new Date( scheduledClass.end_time_utc ) ).toTimeString()
    .split( ` ` )[0].substring( 0, 5 )
    : getTimeString( new Date( new Date( scheduledClass?.timeslot_exact_timestamp as string ).getTime() + ( scheduledClass.duration_minutes * 60 * 1000 ) ) )

  const dateStamp = scheduledClass?.scheduled_date?.substring( 0, 10 )

  const styleOverrides = `--btn-background: ${tailwindColors.pink[`3`]}; --btn-border: ${tailwindColors.pink[`3`]}; --date-btn-cal-background: ${tailwindColors.pink[`3`]}; --btn-hover-text: ${tailwindColors.gray.dark}; --date-btn-text: ${tailwindColors.gray.dark};`

  return (
    <>
      <div className="flex mt-4 mb-5 gap-5 relative cursor-pointer">
        <div className="flex flex-col">
          <div className="flex gap-2">
            <div onClick={() => setShowDetailsModal( true )}>
              {classDetails ?
                <img
                  width={70} height={70}
                  src={classDetails?.small_image.url}
                  alt={classDetails?.small_image.label}
                /> : (
                  <div className="w-12 h-12 bg-gray-300" />
                )
              }
            </div>
            <div className="w-full max-w-sm px-3 flex flex-col">
              {
                completed ?
                  <div
                    onClick={() => setShowDetailsModal( true )}
                  >
                    <p className="hub-title">{scheduledClass.class_title}</p>
                    <p className="hub-p">{`${scheduledClass.scheduled_date_pretty} ${scheduledClass.begin_time_pretty}, ${scheduledClass.duration_minutes}mins`}</p>
                  </div>
                  :
                  <div
                    className="relative"
                    onMouseEnter={() => { hideAddToCalendar( id ?? scheduledClass.class_id ) }}
                    onMouseOut={() => { displayAddToCalendar( id ?? scheduledClass.class_id ) }}
                  >
                    <AddToCalendarButton
                      name={scheduledClass.class_title}
                      size="3"
                      iCalFileName="lactation-link-calendar-event-download"
                      buttonStyle="date"
                      forceOverlay
                      styleLight={styleOverrides}
                      styleDark={styleOverrides}
                      description={getCalendarEventDescription( scheduledClass )}
                      options={[ `Apple`, `Google`, `Outlook.com`, `Microsoft365`, `Yahoo` ]}
                      location={scheduledClass.zoom_link}
                      startDate={dateStamp}
                      endDate={dateStamp}
                      startTime={startTime}
                      endTime={endTime}
                      timeZone="currentBrowser"
                      hideBranding
                      hideCheckmark
                      customCss="/resources/css/atcb-override.css"
                    />
                    <p id={`add-to-calendar-text-${id ?? scheduledClass.class_id}`} className="a absolute left-16 bottom-1 text-xs z-40 pointer-events-none">{`Add to Calendar`}</p>
                  </div>

              }
              <div>
                {
                  scheduledClass?.class_id?.length && !completed ?
                    <div className="flex mt-4 gap-6 items-start justify-start">
                      <p
                        onClick={handleLaunchZoom}
                        className="a text-sm text-center"
                      >
                        {`Join Class`}
                      </p>
                      {
                        !hideActions &&
                        <>
                          <p className="a text-sm text-center">
                            <Link to={`/reschedule/${scheduledClass.class_id}${window.location.search}`}>
                              {`Reschedule`}
                            </Link>
                          </p>
                          <p
                            className="a text-sm text-center"
                            onClick={() => { setShowCancelModal( true ) }}
                          >
                            {`Cancel`}
                          </p>
                        </>
                      }
                    </div>
                    :
                    <div className="flex mt-4 gap-6 items-start justify-start">
                      {
                        scheduledClass?.class_id &&
                        <p
                          className="a text-sm text-center"
                          onClick={handleRetakeClass}
                        >
                          {`Retake Class`}
                        </p>
                      }
                      {
                        classDetails?.course_material_link &&
                        <p className="a text-sm text-center">
                          {/* have to strip out the html here since we are provided a string link within a wysiwyg */}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={classDetails.course_material_link.replace( /(<([^>]+)>)/gi, `` )}
                          >
                            {`Resource Guide`}
                          </a>
                        </p>
                      }
                      <p
                        className="a text-sm text-center"
                        onClick={() => setShowDetailsModal( true )}
                      >
                        {`View Class Details`}
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        {
          completed &&
          <img
            width={16} height={16}
            src={checkmark}
            alt={`checkmark`}
            className="absolute top-0 left-8"
          />
        }
      </div>
      <CancelModal
        onCancel={handleCancelClass}
        seriesClasses={seriesClasses}
        display={showCancelModal}
        setDisplay={setShowCancelModal}
      />
      <ClassDetailsModal
        key={`${showDetailsModal}-${scheduledClass.event_instance_id}`}
        scheduledClass={scheduledClass}
        image={classDetails?.image}
        description={classDetails?.short_description}
        isOpen={showDetailsModal}
        handleClose={() => setShowDetailsModal( false )}
        onCancelClass={handleCancelClass}
        completed={completed}
        scheduledClasses={scheduledClasses}
      />
    </>
  )
}