import React from 'react'
import PropTypes from 'prop-types'
import { ImgTab } from 'components/ImgTab'
import { BundleCheck } from 'assets'
import ReactHtmlParser from 'react-html-parser'

function BundleCard({ bundleDetails, selectBundle, openModal, readOnly }) {

  const transform = ( node ) => {
    if ( node?.name === `li` ) node.attribs.class = `bullet-point`
  }

  return (
    <div
      className={`w-full ring-primary ${readOnly ? `ring-4` : `ring-1` } rounded-lg mb-16 relative pb-10 max-w-lg mx-auto cursor-pointer`}
      onClick={openModal}
    >
      {
        bundleDetails?.recommended &&
        <div className="absolute -right-1 top-2 md:top-5 z-10">
          <ImgTab />
        </div>
      }
      <img
        src={bundleDetails?.image_path}
        alt="group bundle image"
        className="w-full rounded-t-lg h-64 object-contain bg-gray-white"
        height={80}
      />
      <div className="px-5 flex flex-col justify-center items-center text-center">
        <p className="text-2xl mt-5 mb-2 font-semibold">{bundleDetails?.bundle_title}</p>
        <div className="text-lg mb-2">
          {
            bundleDetails?.bundle_description?.length > 0 &&
            ReactHtmlParser( bundleDetails?.bundle_description, {
              transform
            })
          }
        </div>
        <div className="flex items-center gap-5 justify-center">
          {/* Reviews have been descoped for initial release <ReviewDisplay reviews={bundleDetails.reviews} number_of_reviews={bundleDetails.number_of_reviews} /> */}
        </div>
      </div>
      <div className={`absolute ${readOnly ? `-bottom-10` : `-bottom-5`} w-full flex justify-center items-center`}>
        {
          readOnly ?
            <img
              src={BundleCheck}
              alt="schedule bundle check"
              width={80}
              height={80}
            />
            :
            <button
              className="primary-button" onClick={selectBundle}
            >
              {`ADD & SCHEDULE`}
            </button>
        }
      </div>
    </div>
  )
}

BundleCard.propTypes = {
  bundleDetails: PropTypes.object,
  openModal: PropTypes.func,
  selectBundle: PropTypes.func,
  readOnly: PropTypes.bool
}

export default BundleCard