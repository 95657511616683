import create from 'zustand'

type ActiveTagState = {
  activeTag: string;
  updateActiveTag: ( _tagName: string ) => void;
  clearActiveTag: () => void;
}

const useActiveTag = create<ActiveTagState>( ( set ) => {
  return {
    activeTag: ``,
    clearActiveTag: () => {
      set({
        activeTag: ``
      })
    },
    updateActiveTag: ( tagName: string ) => {
      set({
        activeTag: tagName
      })
    }
  }
})

export default useActiveTag