import { schedule } from "assets/herHub"
import { useHistory } from "react-router-dom"
import { BUNDLE_SELECTION_BASE_PATH } from "routes"

import Tile from "./Tile"

export default function ShopAndSchedule() : JSX.Element {
  const history = useHistory()

  return (
    <Tile
      className="flex gap-6 cursor-pointer"
      onClick={() => { history.push( `${BUNDLE_SELECTION_BASE_PATH}${window.location.search ? window.location.search.concat( `&myAccount=true` ) : `?myAccount=true`}` ) }}
    >
      <img
        src={schedule} width={80} height={80}
      />
      <h1 className="hub-heading text-gray-dark self-center w-40">{`Schedule Classes`}</h1>
    </Tile>
  )
}