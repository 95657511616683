import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ScheduleClass } from 'views/ScheduleClass'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import useSelectedClasses from 'hooks/useSelectedClasses'
import { SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH, INDIVIDUAL_CLASSES_CONFIRM_PATH, INDIVIDUAL_CLASSES_THANK_YOU_PATH } from './utils/constants'
import PageNavigator from 'components/pageNavigator'
import { ThemedModal } from 'components/ThemedModal'
import { Class, TimeSlot } from 'types/shopScheduleTypes'
import { submitPartialScheduling } from 'views/ScheduleClass/utils/utils'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import usePatientDetails from 'hooks/usePatientDetails'
import { Confirm } from 'views/Confirm'
import { ShopScheduleThankYou } from 'views/ThankYou'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import useSeriesReference from 'hooks/useSeriesReference'
import useAuthStore from 'stores/auth'
import useEligibleAppointments from 'hooks/useEligibleAppointments'

function ScheduleIndividualClasses() : JSX.Element {
  const [ pageNames, setPageNames ] = useState<string[]>( [] )
  const [ modalMessageStatus, setModalMessageStatus ] = useState<boolean>( true )
  const [ selectionError, setSelectionError ] = useState<string>( `` )

  const history = useHistory()
  const { selectedClasses, selectTime, excludeDays, removeTimes, toggleSkipScheduling } = useSelectedClasses()
  const { seriesReference, setSeriesReference } = useSeriesReference()
  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  const { timezone, setTimezone } = usePatientDetails()
  const { excludeDays: prevScheduledDays } = useEligibleAppointments( timezone )

  useEffect( () => {
    if ( !selectedClasses || !selectedClasses?.length ) history.push( INDIVIDUAL_SELECTION_BASE_PATH.concat( window.location.search ) )
    else {
      let firstUnscheduled:number|null = null
      const pages = selectedClasses.map( ( _class, i ) => {
        if ( !_class.selected_timeslot && !firstUnscheduled && !_class.skip_scheduling ) firstUnscheduled = i + 1

        return SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH.concat( `/class-${i+1}` )
      })
      pages.push( INDIVIDUAL_CLASSES_CONFIRM_PATH, INDIVIDUAL_CLASSES_THANK_YOU_PATH )
      setPageNames( pages )

      return firstUnscheduled ? history.push( `${SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH}/class-${firstUnscheduled}${window.location.search}` ) : history.push( `${INDIVIDUAL_CLASSES_CONFIRM_PATH}${window.location.search}` )
    }
  }, [ selectedClasses ] )

  const handlePartialSchedule = ( selectedSlot: TimeSlot, classInfo: Class ) => {
    selectedSlot.time_zone = timezone ?? `US/Eastern`
    const status = selectTime( classInfo, selectedSlot )
    if ( status ) {
      submitPartialScheduling( classInfo.class_sku, selectedSlot, authHeader )
      if ( classInfo.is_series ) setSeriesReference( classInfo.class_series_pk, classInfo.class_sequence, selectedSlot.timeslot_exact_timestamp )
    } else setSelectionError( `Oops... we encountered an exception attempting to select this timeslot.` )
  }

  if ( selectionError ) return <SchedulingErrorBlock message={selectionError} />

  if ( !pageNames.length || !timezone || !selectedClasses?.length || !prevScheduledDays ) return <AppointmentsLoadingBox message={`Please hold tight while we grab your details...`} />

  return (
    <div className="flex flex-col relative justify-center items-center overflow-hidden px-3">
      <ThemedModal
        message="Hey Mama! Time to schedule your classes. Select from the available dates and times using the calendar below."
        open={modalMessageStatus}
        handleClose={() => { setModalMessageStatus( false ) }}
      />
      <PageNavigator
        pageNames={pageNames}
        childSharedProps={{
          totalSteps: selectedClasses?.length
        }}
        pageClassName="w-full"
      >
        {
          selectedClasses?.length &&
          selectedClasses.map( ( classInfo, i ) => {
            return (
              <ScheduleClass
                key={classInfo?.class_id}
                step={i + 1}
                excludeDays={new Set( [ ...Array.from( excludeDays ), ...Array.from( prevScheduledDays || [] ) ] ) as Set<string>}
                totalSteps={selectedClasses?.length}
                classInfo={classInfo}
                handlePartialSchedule={( selectedSlot: TimeSlot ) => { handlePartialSchedule( selectedSlot, classInfo ) }}
                timezone={timezone}
                setTimezone={setTimezone}
                seriesReference={seriesReference}
                removeTimes={removeTimes}
                toggleSkipScheduling={toggleSkipScheduling}
              />
            )
          })
        }
        <Confirm
          classes={selectedClasses}
          removeTimes={removeTimes}
          toggleSkipScheduling={toggleSkipScheduling}
        />
        <ShopScheduleThankYou />
      </PageNavigator>
    </div>
  )
}

export default ScheduleIndividualClasses