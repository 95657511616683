import { useEffect, useState } from 'react'
import { ScheduledClass } from 'types/shopScheduleTypes'
import { useHistory, useParams } from 'react-router-dom'
import useScheduledClasses from 'hooks/useScheduledClasses'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'

type SeriesRescheduleDisplayReturn = {
  pageNames: string[] | null,
  setPageNames: React.Dispatch<React.SetStateAction<string[] | null>>,
  seriesClasses: ScheduledClass[],
  setSeriesClasses: React.Dispatch<React.SetStateAction<ScheduledClass[]>>
}

function useSeriesRescheduleDisplay() : SeriesRescheduleDisplayReturn {

  const [ pageNames, setPageNames ] = useState<string[] | null>( null )
  const [ seriesClasses, setSeriesClasses ] = useState<ScheduledClass[]>( [] )

  const { scheduledClasses, completedClasses } = useScheduledClasses()

  const params = useParams<{classId?: string}>()
  const history = useHistory()

  useEffect( () => {
    const { classId } = params
    if ( scheduledClasses?.length && classId ) {
      const classItem = scheduledClasses.find( ( c : ScheduledClass ) => {
        return c.class_id === classId
      })
      // class id in params is not present in scheduled classes so go back to hub page
      if ( !classItem ) return history.push( `${HER_HUB_PATH}${window.location.search}` )
      else if ( !classItem?.class_series_pk ) setPageNames( [] )
      else {
        // go through and get all the other classes in the series
        const allSeriesClasses:ScheduledClass[] = []

        scheduledClasses.forEach( ( c : ScheduledClass ) => {
          // if the patient has already completed this class we don't need to reschedule it
          const classIsCompleted = completedClasses.find( ( cc : ScheduledClass ) => {
            return cc.class_id === c.class_id
          })
          if ( c.class_series_pk === classItem.class_series_pk && !classIsCompleted ) allSeriesClasses.push( c )
        })

        allSeriesClasses.sort( ( a: ScheduledClass, b: ScheduledClass ) => {
          return a.class_sequence - b.class_sequence
        })

        const updatedPageNames = [
          ...allSeriesClasses.map( ( c: ScheduledClass ) => {
            return `/reschedule/${c.class_id}`
          })
        ]

        setSeriesClasses( [ ...allSeriesClasses ] )
        setPageNames( updatedPageNames )
        // go to the first page
        history.push( `${updatedPageNames[0]}${window.location.search}` )
      }
    }
  }, [ scheduledClasses ] )

  return {
    pageNames,
    setPageNames,
    seriesClasses,
    setSeriesClasses
  }
}

export default useSeriesRescheduleDisplay