import create from 'zustand'
import { Validators } from 'components/Forms/components'

type ForgotPasswordStore = {
  email: string;
  newPassword: string;
  newPasswordConfirmation: string;
  setFieldState: ( _fieldName : string, _value : string ) => void;
  getToken: () => string | null;
}

const useForgotPasswordStore = create<ForgotPasswordStore>( ( set ) => {
  return {
    email: ``,
    newPassword: ``,
    newPasswordConfirmation: ``,
    setFieldState: ( name: string, value: string ) => {
      set({
        [name]: value
      })
    },
    getToken: () => {
      return new URLSearchParams( window.location.search ).get( `token` )
    }
  }
})

export default useForgotPasswordStore

export const Validate = ( name : string, value : string, altValue? : string ) : string => {
  if ( name === `email` ) return Validators.email( value ) ? `` : `Please enter a valid email.`
  if ( name === `newPassword` ) return Validators.password( value ) ? `` : `Your password must be at least 8 characters, contain lower case, upper case, and at least one number.`
  if ( name === `newPasswordConfirmation` ) return value === altValue ? `` : `Passwords do not match.`

  return ``
}