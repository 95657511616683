import useSelectedBundle from "hooks/useSelectedBundle"
import { useEffect, useState } from "react"
import useAuthStore from "stores/auth"
import { Bundle, Class, ScheduledClass, TimeSlot } from "types/shopScheduleTypes"
import { reportToSentry } from "utils/reportToSentry"
import { getBundleTimeslots } from "../utils/utils"
import useScheduledClasses from "hooks/useScheduledClasses"

type GetBundleDetailsReturn = {
  bundleDetails: Bundle | null;
  excludeDays: Set<string>;
  error: string;
  loading: boolean;
  selectTime: ( _selected_timeslot: TimeSlot, _classInfo: Class ) => boolean;
  removeTimes: ( _classes: Class[] ) => void;
  toggleSkipScheduling: ( _classInfo: Class ) => void;
}

export const useGetBundleDetails = ( timezone: string | null ) : GetBundleDetailsReturn => {

  const [ bundleDetails, setBundleDetails ] = useState<Bundle | null>( null )
  const [ excludeDays, setExcludeDays ] = useState<Set<string>>( new Set() )
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<string>( `` )

  const { selectedBundle } = useSelectedBundle()
  const { loading: loadingCompletedClasses, completedClasses } = useScheduledClasses()
  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  useEffect( () => {
    if ( selectedBundle && timezone && !loadingCompletedClasses && ( !bundleDetails?.classes?.length || !bundleDetails?.classes[0][ `${timezone}_timeslots` ] ) ) {
      setLoading( true )
      getBundleTimeslots( selectedBundle.bundle_sku, timezone, authHeader ).then( ( data ) => {
        if ( data?.data && data?.meta?.status === `OK` ) {
          // This is to save the timeslots according to timezone so there is no need
          // to refetch if patient navigates back to an already fetched timezone

          // here we are filtering out series classes that have been completed already since they don't need to be scheduled again
          const bundleClassData = data.data.classes.filter( ( classItem: Class ) => {
            if ( !classItem.is_series ) return true
            const completedSeriesClass = completedClasses.find( ( completedClass: ScheduledClass ) => {
              return completedClass.class_id === classItem.class_id
            })

            return !completedSeriesClass
          })
          formatTimeslots( Object.assign({}, data.data, {
            classes: bundleClassData
          }) )
        } else {
          setError( `Oops... We encountered an exception attempting to get the available times for your bundle.` )
          reportToSentry( new Error( `Shop and Schedule: Error attempting to get bundle detail time slots` ), {
            selectedBundle: JSON.stringify( selectedBundle ),
            authHeader
          })
        }

        return setLoading( false )
      })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Shop and Schedule: Error attempting to get bundle detail time slots`, {
            cause: error
          }), {
            selectedBundle: JSON.stringify( selectedBundle ),
            authHeader
          })
          setError( `Oops... We encountered an exception attempting to get the available times for your bundle.` )

          return setLoading( false )
        })
    }

  }, [ timezone, loadingCompletedClasses ] )

  const formatTimeslots = ( bundleData: Bundle ) => {
    const updateBundleDetails = Object.assign({}, bundleDetails ?? bundleData, {
      image_path: selectedBundle?.image_path ?? ``,
      bundle_short_description: selectedBundle?.bundle_short_description ?? ``,
      bundle_description: selectedBundle?.bundle_description ?? ``
    })
    updateBundleDetails.classes.forEach( ( classDetails: Class, i: number ) => {
      const selectedBundleClassData = selectedBundle?.classes?.find( ( classItem: Class ) => {
        return classItem.class_sku === classDetails.class_sku
      })
      // we get class sequence from /bundles but not from bundle details endpoint
      const selectedBundleClassIndex = selectedBundle?.classes?.findIndex( ( dataClass: Class ) => { return classDetails.class_id === dataClass.class_id })
      const dataClassIndex = bundleData.classes.findIndex( ( dataClass: Class ) => { return classDetails.class_id === dataClass.class_id })
      updateBundleDetails.classes[i] = Object.assign({}, updateBundleDetails.classes[i], {
        [ `${timezone}_timeslots` ]: bundleData.classes[dataClassIndex].timeslots,
        class_duration: bundleData.classes[dataClassIndex].timeslots?.length ? bundleData.classes[dataClassIndex].timeslots[0].duration_minutes : ``,
        small_image_path: selectedBundleClassData?.small_image_path ?? ``,
        image_path: selectedBundleClassData?.image_path ?? ``,
        class_sequence: selectedBundleClassIndex ? selectedBundle?.classes[ selectedBundleClassIndex ].class_sequence : null
      })
      delete updateBundleDetails.classes[i].timeslots
    })

    setBundleDetails( updateBundleDetails )
  }

  const selectTime = ( selected_timeslot: TimeSlot, classInfo: Class ) => {
    if ( !bundleDetails || !bundleDetails?.classes?.length ) return false
    const updateBundleDetails = bundleDetails
    const updateDaySet = new Set( excludeDays )

    const classIndex = bundleDetails.classes.findIndex( ( currentClass: Class ) => { return currentClass.class_id === classInfo.class_id })
    if ( classIndex === -1 ) return false
    const dateVal = updateBundleDetails.classes[classIndex]?.selected_timeslot?.scheduled_date
    // remove date value from excluded days if the user is changing dates
    if ( dateVal ) updateDaySet.delete( dateVal )
    selected_timeslot.time_zone = timezone ?? `US/Eastern`
    updateBundleDetails.classes[classIndex] = Object.assign({}, updateBundleDetails.classes[classIndex], {
      selected_timeslot
    })
    setBundleDetails( Object.assign({}, updateBundleDetails ) )
    updateDaySet.add( selected_timeslot.scheduled_date.substring( 0, 10 ) )
    setExcludeDays( new Set( updateDaySet ) )

    return true
  }

  const removeTimes = ( removeClasses: Class[] ) => {
    if ( !bundleDetails || !bundleDetails?.classes?.length ) return
    const updateBundleDetails = bundleDetails
    const updateDaySet = new Set( excludeDays )

    removeClasses.forEach( ( removeClassInfo: Class ) => {
      const classIndex = updateBundleDetails.classes.findIndex( ( classItem: Class ) => {
        return removeClassInfo.class_id === classItem.class_id
      })

      if ( classIndex !== -1 ) {
        const dateVal = updateBundleDetails.classes[ classIndex ]?.selected_timeslot?.scheduled_date?.substring( 0, 10 )
        // remove date value from excluded days if the user is changing dates
        if ( dateVal ) updateDaySet.delete( dateVal )
        delete updateBundleDetails.classes[ classIndex ].selected_timeslot
        setBundleDetails( Object.assign({}, updateBundleDetails ) )
        setExcludeDays( new Set( updateDaySet ) )
      }
    })

  }

  const toggleSkipScheduling = ( classInfo: Class ) => {
    if ( !bundleDetails || !bundleDetails?.classes?.length ) return
    const updateBundleDetails = bundleDetails

    const classIndex = updateBundleDetails.classes.findIndex( ( classItem: Class ) => {
      return classInfo.class_id === classItem.class_id
    })
    if ( classIndex !== -1 ) {
      updateBundleDetails.classes[ classIndex ].skip_scheduling = !updateBundleDetails.classes[ classIndex ].skip_scheduling
      setBundleDetails( Object.assign({}, updateBundleDetails ) )
    }
  }


  return {
    bundleDetails,
    excludeDays,
    error,
    loading,
    selectTime,
    removeTimes,
    toggleSkipScheduling
  }
}