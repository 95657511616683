import { useEffect, useState } from 'react'
import { Bundle, Class } from 'types/shopScheduleTypes'
import { reportToSentry } from 'utils/reportToSentry'
import { fetchBundles } from '../utils/utils'
import { getProductsQueryBySkuArray } from 'graphql/queries/products'
import useApolloClient from 'apollo/apolloClient'
import { MagentoProduct } from 'types'
import { useHistory } from 'react-router-dom'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import useSelectedBundle from 'hooks/useSelectedBundle'
import { SCHEDULE_BUNDLE_BASE_PATH } from 'views/ScheduleBundle/utils/constants'
import useAuthStore from 'stores/auth'

function useFetchBundles() : { bundles: Bundle[] | null; error: string } {

  const [ bundles, setBundles ] = useState<Bundle[] | null>( null )
  const [ error, setError ] = useState<string>( `` )

  const history = useHistory()
  const { setSelectedBundle } = useSelectedBundle()
  const apolloClient = useApolloClient()
  const { buildAuthorizer } = useAuthStore()

  const defaultErrorMessage = `Oops... We encountered an error getting the available bundles and classes for you.`
  const searchParams = new URLSearchParams( location.search )
  const querySku = searchParams.get( `sku` )

  useEffect( () => {
    fetchBundles( buildAuthorizer() )
      .then( data => {
        if ( data?.data?.bundles && !data?.errors?.length && data?.meta?.status === `OK` ) {
          if ( data?.data?.bundles?.length === 0 ) return history.replace( `${INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}` )

          return getAllBundleData( data.data.bundles )
        }

        reportToSentry( new Error( `Shop and Schedule: Error getting bundles` ), {
          authorizer: buildAuthorizer()
        })
        setError( defaultErrorMessage )
      })
      .catch( ( error: Error ) => {
        setError( defaultErrorMessage )
        reportToSentry( new Error( `Shop and Schedule: Error getting bundles`, {
          cause: error
        }), {
          authorizer: buildAuthorizer()
        })
      })
  }, [] )

  // @NOTE we only want to drop a user directly in the bundle sched flow with a preselected bundle
  // 1 time when a user first loads up the app
  // after that initial drop if they click "To change your bundle, click here" we want them to see all eligible bundles + individual class sched tile
  useEffect( () => {
    const initialRedirectFired = sessionStorage.getItem( `initial_redirect_fired` )
    if ( bundles ) {
      for ( const bundle of bundles ) {
        if ( ( bundle.unclaimed_bundle && ( !initialRedirectFired || initialRedirectFired !== `true` ) ) || bundle.bundle_sku === querySku ) {
          setSelectedBundle( bundle )
          sessionStorage.setItem( `initial_redirect_fired`, `true` )

          return history.push( SCHEDULE_BUNDLE_BASE_PATH.concat( window.location.search ) )
        }
      }
    }
  }, [ bundles ] )

  // this gets all the magento data and internal data and formats it
  const getAllBundleData = async ( bundles: Bundle[] ) => {

    const bundleProductsResult = await getBundleProducts( bundles )
    const classProductsResult = await getClassProducts( bundles )

    if ( !bundleProductsResult?.data?.resupplyProducts?.items || !classProductsResult?.data?.resupplyProducts?.items ) {
      reportToSentry( new Error( `Shop and Schedule: Error getting bundle or bundle class magento data` ), {
        bundleProductsResult: JSON.stringify( bundleProductsResult ),
        classProductsResult: JSON.stringify( classProductsResult )
      })

      return setError( defaultErrorMessage )
    }


    const allBundleData:Bundle[] = bundles.map( ( bundleItem: Bundle ) => {
      const bundleProduct:MagentoProduct = bundleProductsResult.data.resupplyProducts.items.find( ( resultBundle: MagentoProduct ) => {
        return bundleItem?.bundle_sku === resultBundle.sku
      })

      const updateBundleItem:Bundle = bundleItem

      updateBundleItem.classes = updateBundleItem.classes.map( ( classItem: Class ) => {
        const magentoClassDetails:MagentoProduct = classProductsResult.data.resupplyProducts.items.find( ( resultClass: MagentoProduct ) => {
          return classItem.class_sku === resultClass.sku
        })
        if ( magentoClassDetails ) {
          return {
            ...classItem,
            small_image_path: magentoClassDetails?.small_image?.url,
            image_path: magentoClassDetails?.image?.url,
            class_title: magentoClassDetails?.name,
            class_description: magentoClassDetails?.description?.html
          } as Class
        }

        return classItem
      })

      return {
        ...bundleItem,
        image_path: bundleProduct?.image?.url,
        bundle_title: bundleProduct?.name,
        bundle_description: bundleProduct?.description?.html,
        bundle_short_description: bundleProduct?.short_description?.html
      }
    })

    setBundles( allBundleData )
  }

  // this gets the magento product data for the bundle classes
  const getClassProducts = async ( bundles: Bundle[] ) => {
    const classSkusArray:string[] = []
    bundles?.forEach( ( bundle: Bundle ) => {
      bundle?.classes?.forEach( ( classItem: Class ) => {
        if ( classItem.class_sku ) classSkusArray.push( classItem.class_sku )
      })
    })

    const classProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: classSkusArray
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          classSkusArray: JSON.stringify( classSkusArray )
        })

        return setError( defaultErrorMessage )
      })

    return classProductsResult
  }

  // this gets the magento product data for the bundle
  const getBundleProducts = async ( bundles: Bundle[] ) => {
    const bundleSkuArray:string[] = bundles?.filter( ( bundleItem: Bundle ) => { return Boolean( bundleItem.bundle_sku ) })?.map( ( bundleItem: Bundle ) => { return bundleItem.bundle_sku.toString() }) ?? []

    const bundleProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: bundleSkuArray,
        pageSize: bundleSkuArray.length
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          bundleSkuArray: JSON.stringify( bundleSkuArray )
        })

        return setError( defaultErrorMessage )
      })


    bundleSkuArray.forEach( ( bundleSku: string ) => {
      if ( !bundleProductsResult?.data?.resupplyProducts?.items?.find( ( resultBundle: MagentoProduct ) => {
        return bundleSku === resultBundle.sku
      }) ) {
        reportToSentry( new Error( `Resupply: No items returned with sku` ), {
          bundleSku
        })
      }
    })

    return bundleProductsResult
  }

  return {
    bundles,
    error
  }
}

export default useFetchBundles