import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useAuthStore from 'stores/auth'

function TokenCheck() : null {

  const location = useLocation()
  const { setCurrentToken } = useAuthStore()

  useEffect( () => {
    if ( location.search ) {
      const searchParams = new URLSearchParams( location.search )
      const token = searchParams.get( `mct` )
      if ( token ) setCurrentToken( token )
    }
  }, [ location ] )

  return null
}

export default TokenCheck