import { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import TextField from 'components/TextField'
import Select from 'components/Select'
import ButtonLoader from 'components/ButtonLoader'
import {
  getHowDidYouHearValues,
  buildReferralUrl,
  submitQualifyLeads,
  hasCookies
} from 'apis'
import useInsuranceTypes from 'hooks/useInsuranceTypes'
import { STATES } from 'utils/constants'
import { IntakeFormSchema } from 'utils/validationSchema'
import { getMDY, formatPhoneNumber } from 'utils'
import { reportToSentry } from 'utils/reportToSentry'
import { TextInput } from 'components/Forms/components'
import TermsChecker from '../TermsChecker'
import useIntakeStore from 'stores/intake'

function IntakeForm() {
  const history = useHistory()

  const [ values, setValues ] = useState({
    first_name: ``,
    last_name: ``,
    phone: ``,
    baby_due_date: ``,
    street1: ``,
    city: ``,
    zipcode: ``,
    state: ``,
    insurance_primary_provider: ``,
    parent_payer_pk: ``,
    insurance_primary_policy_number: ``,
    how_did_you_hear_about_us: ``,
    terms: ``
  })
  const [ email, dob ] = useIntakeStore( state => [ state.email, state.dob ] )
  const [ errors, setErrors ] = useState({})
  const [ isLoading, setIsLoading ] = useState( false )
  const [ hearOptions, setHearOptions ] = useState( null )
  const [ isValidUSPhoneNumber, setIsValidUSPhoneNumber ] = useState( true )
  const [ phoneErrorType, setPhoneErrorType ] = useState( `` )

  const { insuranceTypes } = useInsuranceTypes( values.state )

  const areErrorsPresent = useMemo( () => {
    // If any of the errors are present or any of the values are empty then return true
    return Object.values( errors ).some( error => error?.length ) || Object.values( values ).some( value => value === `` )
  }, [ errors ] )

  useEffect( () => {
    const controller = new AbortController()

    // If email or phone isn't acquired form the previous step then redirect back to the lookup page
    if ( !email?.length || !dob?.length ) {
      return history.push( `/intake/lookup/${window.location.pathname.split( `/` )[3]}` )
    }

    getHowDidYouHearValues( buildReferralUrl(), controller ).then( res => {
      if ( res ) {
        const { how_did_you_hear_about_us_options } = res

        const formattedOptions =
          how_did_you_hear_about_us_options.map( option => {
            return {
              name: option,
              value: option
            }
          })

        setHearOptions( [
          {
            name: `Select`,
            value: ``
          },
          ...formattedOptions
        ] )
      }
    })

    return () => {
      controller.abort()
    }
  }, [] )

  useEffect( () => {

    if ( !isValidUSPhoneNumber ) {
      setErrors({
        ...errors,
        [phoneErrorType]: `Must be a valid US phone number.`
      })
      setIsLoading( false )
    }
  }, [ isValidUSPhoneNumber, phoneErrorType ] )

  const handleSubmit = async ( e ) => {

    e.preventDefault()
    const {
      month: due_month,
      date: due_day,
      year: due_year
    } = getMDY( values.baby_due_date )

    setIsLoading( true )
    IntakeFormSchema.validate( values, {
      abortEarly: false
    })
      .then( () => {
        submitQualifyLeads({
          ...values,
          email,
          dob,
          due_month,
          due_year,
          due_day
        })
          .then( ( data ) => {
            if ( data.meta.status === `OK` ) {
              history.push( `/intake/thank-you` )
            }
            // due to the middleware checking for a valid us phone number we are doing this check to match on the FE
            setIsValidUSPhoneNumber( true )
            if ( data.meta.status === `Bad Request` ) {
              data.errors.find( value => {
                value.path.find( errorType => {
                  if ( errorType === `phone` ) {
                    setIsValidUSPhoneNumber( false )
                    setPhoneErrorType( errorType )
                    setIsLoading( false )
                  }
                })
              })
            }
          })
          .catch( ( err ) => {
            reportToSentry( `There was an error submitting intake form`, {
              err
            })
            setIsLoading( false )
          })
      })
      .catch( err => {
        setIsLoading( false )
        const errors = err.inner.reduce(
          ( acc, error ) => {
            return {
              ...acc,
              [error.path]: error.message
            }
          },
          {}
        )
        setErrors( errors )
      })
  }

  const updateError = ( err ) => {
    const newErrors = err.inner.reduce( ( objAccumulator, error ) => {
      return {
        ...objAccumulator,
        [error.path]: error.message
      }
    }, {})
    setErrors({
      ...newErrors
    })
  }

  const onChange = ( key, value ) => {
    const updatedValues = {
      ...values,
      [key]: value
    }

    // 0 is for "other"
    if ( key === `friendly_name` ) {
      updatedValues.insurance_primary_provider = value
      delete updatedValues.friendly_name
      updatedValues.parent_payer_pk = insuranceTypes.find( payer => {
        return payer.value === value
      })?.parent_payer_pk ?? 0
    }
    setValues( updatedValues )
    IntakeFormSchema.validateAt( key === `friendly_name` ? `parent_payer_pk` : key, updatedValues, {
      abortEarly: false
    })
      .then( () => {
        let _errors = {
          ...errors
        }
        delete _errors[key]
        setErrors({
          ..._errors
        })
      })
      .catch( ( err ) => {
        if ( key === `baby_due_date` ) {
          updateError( err )
        }
      })
  }

  const onBlur = ( key ) => {
    const updatedValues = {
      ...values
    }

    // 0 is for "other"
    if ( key === `friendly_name` ) {
      updatedValues.insurance_primary_provider = values.insurance_primary_provider
      delete updatedValues.friendly_name
      updatedValues.parent_payer_pk = insuranceTypes.find( payer => {
        return payer.value === values.insurance_primary_provider
      })?.parent_payer_pk ?? 0
    }

    IntakeFormSchema.validateAt( key === `friendly_name` ? `parent_payer_pk` : key, updatedValues, {
      abortEarly: false
    })
      .catch( err => {
        updateError( err )
      })
  }

  return (
    <div className="py-4 px-4 md:max-w-screen-md mx-auto lg:max-w-screen-lg xl:max-w-screen-xl space-y-3">
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            name="first_name"
            required
            value={values.first_name}
            label="First Name"
            errorMessage={errors.first_name}
            onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
            autoFocus
          />
        </div>
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            name="last_name"
            required
            value={values.last_name}
            label="Last Name"
            errorMessage={errors.last_name}
            onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex-1 md:mt-9 md:mr-2.5 sm:mt-0">
          <TextInput
            className="input w-full"
            type="text"
            name="phone"
            required
            value={values.phone}
            label="Phone"
            placeholder="888-555-1212"
            errorMessage={errors.phone}
            onChange={({ target: { name, value } }) => { return onChange( name, formatPhoneNumber( value ) ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextField
            type="dob"
            error={errors.baby_due_date}
            label="Due Date/Baby Birth Date"
            onChange={value => { return onChange( `baby_due_date`, value ) }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            name="street1"
            value={values.street1}
            required
            label="Street"
            errorMessage={errors.street1}
            onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            name="city"
            required
            value={values.city}
            label="City"
            errorMessage={errors.city}
            onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            required
            name="zipcode"
            value={values.zipcode}
            label="Zip"
            errorMessage={errors.zipcode}
            onChange={({ target: { name, value } }) => { return onChange( name, value.slice( 0, 5 ) ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <Select
            name="state"
            value={values.state}
            selectValues={STATES}
            label="State"
            required
            error={errors.state}
            onChange={value => { return onChange( `state`, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <Select
            name="parent_payer_pk"
            required
            selectValues={insuranceTypes}
            value={values.friendly_name}
            label="Insurance Type"
            error={errors.parent_payer_pk}
            onChange={value => { return onChange( `friendly_name`, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
            disabled={!insuranceTypes?.length}
          />
        </div>
        {values.insurance_primary_provider === `other` && (
          <div className="flex-1 md:mt-2.5 md:mr-2.5">
            <TextInput
              className="input w-full"
              type="text"
              name="insurance_primary_provider_other"
              value={values.insurance_primary_provider_other}
              label="Name of other Insurance Type"
              errorMessage={errors.insurance_primary_provider_other}
              onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              onBlur={({ target: { name } }) => { return onBlur( name ) }}
            />
          </div>
        )}
        <div className="flex-1 md:mt-2.5 md:mr-2.5">
          <TextInput
            className="input w-full"
            type="text"
            name="insurance_primary_policy_number"
            required
            value={values.insurance_primary_policy_number}
            label="Member ID"
            errorMessage={errors.insurance_primary_policy_number}
            onChange={({ target: { name, value } }) => {
              onChange( name, value.slice( 0, 20 ) )
            }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
            disabled={!values.state}
          />
        </div>
      </div>

      {hearOptions && !hasCookies() && (
        <div className="w-full md:w-1/2 md:mt-2.5 md:pr-2.5">
          <Select
            name="how_did_you_hear_about_us"
            selectValues={hearOptions}
            value={values.how_did_you_hear_about_us}
            error={errors.how_did_you_hear_about_us}
            required
            label="How did you hear about us?"
            onChange={value => { return onChange( `how_did_you_hear_about_us`, value ) }}
            onBlur={({ target: { name } }) => { return onBlur( name ) }}
          />
        </div>
      )}

      <TermsChecker
        value={values.terms}
        onClick={() => { return onChange( `terms`, !values.terms ) }}
        containerClassname="pb-2 px-2 bg-pink-3 bg-opacity-50 mt-5"
        type="checkbox"
        name="terms"
      />
      <p className="text-error text-center my-2">{errors.terms}</p>

      <div className="button-block flex flex-col">

        <button
          type="submit" onClick={handleSubmit} className="primary-button primary-button"
          disabled={isLoading || areErrorsPresent}
        >
          {`Submit`}
          {isLoading && (
            <span>
              <ButtonLoader />
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default IntakeForm
