import React, { useState, useEffect } from 'react'
import BundleCard from 'components/BundleCard/BundleCard'
import useSelectedBundle from 'hooks/useSelectedBundle'
import { useHistory } from 'react-router-dom'
import { Bundle } from 'types/shopScheduleTypes'
import AdditionalDetails from 'components/AdditionalDetails/BundleAdditionalDetails'
import IndividualSelectionCard from 'components/IndividualSelectionCard/IndividualSelectionCard'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import useFetchBundles from './hooks/useFetchBundles'
import useSelectedClasses from 'hooks/useSelectedClasses'
import { SCHEDULE_BUNDLE_BASE_PATH } from 'views/ScheduleBundle/utils/constants'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'

function ShopScheduleMain(): JSX.Element {

  const [ zoomedBundle, setZoomedBundle ] = useState<Bundle | null>( null )

  const { setSelectedBundle } = useSelectedBundle()

  const history = useHistory()

  const { bundles, error } = useFetchBundles()

  useEffect( () => {
    sessionStorage.removeItem( `has_scheduled` )
  }, [] )

  const handleSelectBundle = ( bundle: Bundle ) => {
    setSelectedBundle( bundle )

    // only want selected bundle or selected classes
    useSelectedClasses.destroy()

    history.push( SCHEDULE_BUNDLE_BASE_PATH.concat( window.location.search ) )
  }

  const handleCardClick = ( e: React.MouseEvent<HTMLElement>, bundle: Bundle ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const target = e.target as any
    if ( target?.innerText !== `ADD & SCHEDULE` ) return setZoomedBundle( bundle )
  }

  const handleClose = () => {
    setZoomedBundle( null )
  }

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( !bundles ) return <AppointmentsLoadingBox message={`Please hold tight while we grab your available bundles...`} />

  return (
    <div className="flex justify-center items-center flex-col my-10 px-5 text-center">
      <p className="text-3xl md:text-4xl font-header mb-5">{`Choose Your Classes`}</p>
      <p className="md:text-xl">{`All classes are online and taught live by certified instructors!`}</p>
      <AdditionalDetails
        selectBundle={() => { if ( zoomedBundle ) handleSelectBundle( zoomedBundle ) }}
        bundle={zoomedBundle}
        handleClose={handleClose}
      />
      <div className="my-10 max-w-7xl w-full grid grid-cols-1 xl:grid-cols-2">
        {
          bundles?.length > 0 &&
            <>
              {
                bundles.filter( ( bundle: Bundle ) => { return Boolean( bundle.bundle_sku ) }).map( ( bundle: Bundle ) => {
                  return (
                    <BundleCard
                      key={bundle.bundle_sku}
                      bundleDetails={bundle}
                      selectBundle={() => { return handleSelectBundle( bundle ) }}
                      openModal={( e: React.MouseEvent<HTMLElement> ) => { handleCardClick( e, bundle ) }}
                    />
                  )
                })
              }
            </>
        }
        <IndividualSelectionCard />
      </div>
    </div>
  )
}

export default ShopScheduleMain